import React from 'react'
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react'
import { FormLabel } from '../FormLabel'
import { ImageCropper } from '../ImageCropper'
import empty from './empty-image.svg'
import { useUploadImage } from 'services/useUploadImage'
import { useFetchImage } from 'services/useFetchImage'
import { isEmpty } from 'lodash'
import { HiOutlineTrash } from 'react-icons/hi'
import { AddIcon } from '@chakra-ui/icons'

const Item = ({ data, onDelete }) => {
  const { dataImage } = useFetchImage(data)

  return (
    <Flex
      boxSize="100px"
      marginRight={6}
      borderRadius="8px"
      overflow="hidden"
      backgroundImage={empty}
      position="relative"
      mr="12px"
      mb="12px"
    >
      {!isEmpty(dataImage) && (
        <>
          <IconButton
            position="absolute"
            top="6px"
            right="6px"
            size="xs"
            aria-label="Delete"
            icon={<HiOutlineTrash />}
            onClick={onDelete}
          />
          <Flex
            flex={1}
            backgroundImage={dataImage}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          />
        </>
      )}
    </Flex>
  )
}

export const ImageUploaderCollection = (
  {
    label,
    values,
    isCropRequired,
    colorScheme = 'blue',
    onChange,
    onDelete
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { uploadFile, isLoadingUploadFile } = useUploadImage()

  const isLoading = isLoadingUploadFile

  const handleChange = async (e) => {
    try {
      const dataRequest = {
        name: e.name,
        description: 'Image',
        file: e.uri,
        fileName: e.name,
        fileSize: e.size,
        type: e.type
      }
      const result = await uploadFile(dataRequest)
      onClose()
      onChange(result.id)
    } catch (e) {}
  }

  return (
    <>
      <Box>
        {label && <FormLabel>{label}</FormLabel>}
        <Flex flexWrap="wrap" borderRadius={6} borderWidth={1} padding="16px 8px 4px 20px">
          {values?.map((item, index) => (
            <Item
              key={index}
              data={item}
              onDelete={() => onDelete(index)}
            />
          ))}
          <IconButton
            mr="12px"
            mb="12px"
            boxSize="100px"
            aria-label="Add"
            icon={<AddIcon />}
            onClick={onOpen}
            isLoading={isLoading}
          />
        </Flex>
      </Box>
      <ImageCropper
        colorScheme={colorScheme}
        isOpen={isOpen}
        onClose={onClose}
        isCropRequired={isCropRequired}
        onChange={handleChange}
        optionsCropper={{
          aspectRatio: 1,
          maxCropBoxHeight: 1000,
          maxCropBoxWidth: 1000,
          minCropBoxHeight: 260,
          minCropBoxWidth: 260,
        }}
        optionsCompress={{
          maxWidth: 1000,
          maxHeight: 1000,
        }}
        optionsCroppedCanvas={{
          maxWidth: 1000,
          maxHeight: 1000,
        }}
      />
    </>
  )
}
