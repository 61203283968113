import axios from 'axios'

const ENTITY = 'order'

export const order = Object.freeze({
  async fetchList(data) {
    const response = await axios.post(`/${ENTITY}/search`, data)
    return response.data
  },
  async exportList(data) {
    const response = await axios.post(`/${ENTITY}/export`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // MIME-тип для файлов Excel
      },
      responseType: 'blob' // Важно указать тип ответа как 'blob'
    })
    return response.data;
  },
  async createItem(data) {
    const response = await axios.post(`/${ENTITY}`, data)
    return response.data
  },
  async updateItem(data) {
    const response = await axios.put(`/${ENTITY}`, data)
    return response.data
  },
  async fetchItem(id) {
    const response = await axios.get(`/${ENTITY}/${id}`)
    return response.data
  },
  async deleteItem(id) {
    const response = await axios.delete(`/${ENTITY}/${id}`)
    return response.data
  },
  async fetchKeywords() {
    const response = await axios.get(`/${ENTITY}/keyword`)
    return response.data
  },
  async fetchParameters() {
    const response = await axios.get(`/${ENTITY}/parameter`)
    return response.data
  },
  async updateStateItem({ id, stateId, message }) {
    const response = await axios.put(`/${ENTITY}/${id}/state/${stateId}`, message, {headers: {'Content-Type': 'application/json'}})
    return response.data
  },
  async replyItem(id) {
    const response = await axios.put(`/${ENTITY}/${id}/reply`)
    return response.data
  },
  async updateFavoriteStateItem({ id, stateId }) {
    const response = await axios.put(`/${ENTITY}/${id}/favoriteState/${stateId}`, {headers: {'Content-Type': 'application/json'}})
    return response.data
  },
})
