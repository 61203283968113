import React from 'react'
import { Field, Formik } from 'formik'
import { Button, Checkbox, Fade, Flex, Stack, Text, useBoolean } from '@chakra-ui/react'
import { InputForm } from 'components/InputForm'
import * as Yup from 'yup'
import { useState } from 'react'
import { useSignUp } from './services/useSignUp'
import { PolicyModal } from './PolicyModal'
import { PasswordCheck } from 'components/PasswordCheck/PasswordCheck'
import { useYaMetric } from '../../hooks/useYaMetric'
import { SelectForm } from '../SelectForm'
import { residentOptions, residentType } from '../../constants/residentType'
import { InputPasswordForm } from '../InputPasswordForm'

const formSchema = Yup.object().shape({
  contragentName: Yup.string().required('Заполните поле'),
  email: Yup.string().required('Заполните поле'),
  password: Yup.string().required('Заполните поле'),
  resident: Yup.string().required('Выберите резидента'),
  inn: Yup.string().min(9, 'Минимум 9 цифр').required('Заполните поле')
})

const initialValues = {
  inn: '',
  email: '',
  resident: '',
  contragentName: '',
  password: ''
}

export const RegistrationForm = () => {
  const [isActiveSubmit, setIsActiveSubmit] = useState(false)
  const [isOpenPolicy, setIsOpenPolicy] = useBoolean()
  const { request, isLoading } = useSignUp()
  const { yaClick } = useYaMetric()

  const handleForm = async (values, {setSubmitting, resetForm}) => {
    try {
      await request(values)
      resetForm(initialValues)
      setSubmitting(false)
      yaClick('registration')
    } catch (e) {}
  }

  const handlePolicyClick = (e) => {
    e.preventDefault()
    setIsOpenPolicy.on()
  }

  return (
    <>
      <Fade in>
        <Formik
          enableReinitialize={true}
          onSubmit={handleForm}
          validationSchema={formSchema}
          initialValues={initialValues}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Stack spacing={3}>
                <Field
                  name="resident"
                  label="Резидент"
                  component={SelectForm}
                  placeholder={'Выберите из списка'}
                  isDisabled={isLoading}
                  options={residentOptions}
                />
                {props.values?.resident === residentType.belarus ? (
                  <Field
                    name="inn"
                    label="УНП"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                ) : (
                  <Field
                    name="inn"
                    label="ИНН"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                )}
                <Field
                  name="contragentName"
                  label="Наименование компании или ИП"
                  component={InputForm}
                  isDisabled={isLoading}
                />
                <Field
                  name="email"
                  label="Электронная почта"
                  component={InputForm}
                  isDisabled={isLoading}
                />
                <Field
                  name="password"
                  label="Пароль"
                  component={InputPasswordForm}
                  isDisabled={isLoading}
                />
                <PasswordCheck
                  value={props.values.password}
                />
                <Flex justifyContent="space-between" alignItems="center" py="10px">
                  <Checkbox isChecked={isActiveSubmit} onChange={e => setIsActiveSubmit(e.target.checked)}>
                    <Text fontSize="0.84rem" lineHeight="1.3">
                      Я ознакомлен с условиями пользовательского соглашения и даю согласие на <Text as="span" onClick={handlePolicyClick} color="blue.400">обработку моих персональных данных</Text>
                    </Text>
                  </Checkbox>
                </Flex>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  colorScheme="blue"
                  isDisabled={!isActiveSubmit}
                >
                  Зарегистрироваться
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </Fade>
      <PolicyModal
        isOpen={isOpenPolicy}
        onClose={() => setIsOpenPolicy.off()}
      />
    </>
  )
}
