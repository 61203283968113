import axios from 'axios'
import { dataURIToBlob } from 'utils/dataURIToBlob'

export const formDataHeaderConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

const ENTITY = 'accounts'

export const accounts = Object.freeze({
  async authenticate(data) {
    const response = await axios.post(`/${ENTITY}/authenticate`,  data)
    return response.data
  },
  async fetchItem(id) {
    const response = await axios.get(id ? `/${ENTITY}/${id}` : `/${ENTITY}`)
    return response.data
  },
  async exportList(data) {
    const response = await axios.post(`/${ENTITY}/export`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // MIME-тип для файлов Excel
      },
      responseType: 'blob' // Важно указать тип ответа как 'blob'
    })
    return response.data;
  },
  async deleteItem(id) {
    const response = await axios.delete(`/${ENTITY}/${id}`)
    return response.data
  },
  async fetchList(data) {
    const response = await axios.post(`/${ENTITY}/search`, data)
    return response.data
  },
  async updateItem(data, id) {
    const url = id ? `/${ENTITY}/${id}` : `/${ENTITY}`
    const response = await axios.put(url, data)
    return response.data
  },
  async signUp(data) {
    const response = await axios.post(`/${ENTITY}/signUp`,  data)
    return response.data
  },
  async verify(data) {
    const response = await axios.post(`/${ENTITY}/verify`,  data)
    return response.data
  },
  async sendResetPasswordEmail(data) {
    const response = await axios.post(`/${ENTITY}/sendResetPasswordEmail`,  data)
    return response.data
  },
  async invalidateSession() {
    const response = await axios.post(`/${ENTITY}/invalidateSession`)
    return response.data
  },
  async resetPassword(data) {
    const response = await axios.post(`/${ENTITY}/resetPassword`,  data)
    return response.data
  },
  async uploadFile({ name, description, file, fileName, type, fileSize}) {
    const data = new FormData()
    const dataBlob = await dataURIToBlob(file)
    data.append('File', dataBlob, fileName)
    data.append('Description', description)
    data.append('Name', name)
    data.append('FileName', fileName)
    data.append('FileSize', fileSize)
    data.append('ContentType', type)
    const response = await axios.post(`/${ENTITY}/uploadFile`, data, formDataHeaderConfig)
    return response.data
  },
  async fetchFiles(id) {
    const response = await axios.get(id ? `/${ENTITY}/${id}/files` : `/${ENTITY}/files`)
    return response.data
  },
  async updateFavoriteStateItem({ id, stateId }) {
    const response = await axios.put(`/${ENTITY}/${id}/favoriteState/${stateId}`, {headers: {'Content-Type': 'application/json'}})
    return response.data
  },
  async addRole({ data, id }) {
    const response = await axios.post(`/${ENTITY}/${id}/role`, data)
    return response.data
  },
  async deleteRole({ data, id }) {
    const response = await axios.delete(`/${ENTITY}/${id}/role`, { data })
    return response.data
  },
  async verifyId({ id }) {
    const response = await axios.patch(`/${ENTITY}/verify/${id}`)
    return response.data
  },
})
