import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, Stack, Text
} from '@chakra-ui/react'
import React from 'react'

export const ContactsModal = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Помощь</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Stack spacing={2} pb="10px">
              <Text>По всем вопросам пишите на почтовый адрес:</Text>
              <Link
                color="blue.500"
                href="mailto:info@сделаемвроссии.рф"
                borderWidth={1}
                borderRadius="10px"
                px="12px"
                py="14px"
                textAlign="center"
              >
                official@ciiiz.ru
              </Link>
            </Stack>
            <Stack spacing={2} pb="30px">
              <Text>или телефон:</Text>
              <Text
                borderWidth={1}
                borderRadius="10px"
                px="12px"
                py="14px"
                textAlign="center"
              >
                435-17-00 доб. 1050
              </Text>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
