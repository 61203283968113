import React from 'react'
import { ImageUploaderCollection } from '../ImageUploaderCollection';

export const ImageUploaderCollectionForm = (
  { name, form, push, remove, ...props }
) => {

  const handleChange = (id) => {
    push({ id })
  }

  const handleDelete = (index) => {
    remove(index)
  }

  return (
    <ImageUploaderCollection
      label="Изображения"
      values={form.values[name]}
      onChange={handleChange}
      onDelete={handleDelete}
      {...props}
    />
  )
}
