import { MapBox } from '../MapBox'
import React from 'react'
import { Center, Flex, Spinner } from '@chakra-ui/react'
import { MapPin } from '../MapPin'
import { isEmpty } from 'lodash';
import { useAuthentication } from '../../hooks/useAuthentication'
import { UnauthorizedInfo } from '../UnauthorizedInfo'

export const EntityMap = (
  { data, isLoading, isActivePopover, initialViewState, isHideAuth }
) => {
  const { isAuthenticated } = useAuthentication()

  return (
    <Flex
      minH="500px"
      height="calc(100% - 80px)"
      width="100%"
      flexDirection="column"
      flex={1}
      position="relative"
    >
      {!isAuthenticated && !isHideAuth && (
        <UnauthorizedInfo.Document
          borderWidth={0}
          direction="row"
          justifyContent="center"
        />
      )}
      {isLoading && (
        <Center
          position="absolute"
          zIndex={2}
          left="20px"
          top="20px"
          boxSize="30px"
          backgroundColor="blackAlpha.500"
          borderRadius="full"
        >
          <Spinner color="white" size="sm"/>
        </Center>
      )}
      <MapBox initialViewState={initialViewState}>
        {!isEmpty(data) && data?.map(item => (
          <MapPin
            isActivePopover={isActivePopover}
            key={item?.id}
            {...item}
          />
        ))}
      </MapBox>
    </Flex>
  )
}
