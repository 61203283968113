import React from 'react'
import { Box, Flex, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import Bg from './image/bg.png'
import { useWindowHeight } from 'hooks/useWindowHeight'
import { ErrorBoundary } from '../ErrorBoundary'
import { Logo } from './Logo'

export const LayoutServicesUnauthorized = ({ children }) => {
  const windowHeight = useWindowHeight()
  const bg = useColorModeValue('gray.100', 'whiteAlpha.50')
  const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false })

  return (
    <Flex
      backgroundImage={Bg}
      backgroundRepeat="no-repeat"
      backgroundPosition="left bottom"
      backgroundColor={bg}
      width="100%"
      overflowY="scroll"
      //overflowY="scroll"
      flexDirection="column"
      alignItems="center"
      //justifyContent="center"
      position="relative"
      p="30px"
      flex={1}
      {...!isMobile && {
        height: `${windowHeight}px`
      }}
    >
      <Logo/>
      <ErrorBoundary>
        <Box pt="30px">
          {children}
        </Box>
      </ErrorBoundary>
    </Flex>
  )
}

