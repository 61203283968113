import React, { useEffect, useMemo } from 'react'
import { PageHeading } from 'components/PageHeading'
import {
  Alert, AlertIcon,
  Box,
  Button, Flex,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Form } from 'components/Form'
import { Field, FieldArray, Formik } from 'formik'
import { InputForm } from 'components/InputForm'
import { BlockSection } from 'components/BlockSection'
import { ItemColumns } from 'components/ItemColumns'
import { Okpd2Form } from 'components/Okpd2Form'
import { TextAreaForm } from 'components/TextAreaForm'
import { BlockHeading } from 'components/BlockHeading'
import { BlockGrid } from 'components/BlockGrid'
import { BlockFooter } from 'components/BlockFooter'
import * as Yup from 'yup'
import { BlockCollection } from 'components/BlockCollection'
import { useCreateOrder } from './services'
import { ImageUploaderForm } from 'components/ImageUploaderForm'
import { SelectForm } from 'components/SelectForm'
import { useFetchOrder } from '../services'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useUpdateOrder } from './services'
import { isEmpty } from 'lodash'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { orderStateType } from 'constants/orderStateType'
import { Informer } from 'components/Informer'
import { Container } from 'components/Container'
import { FilesForm } from 'components/FilesForm'
import { SupportRequestForm } from 'components/SupportRequestForm'
import { projectType } from 'constants/projectType'
import { GeoAddressForm } from 'components/GeoAddressForm'
import { getActiveContragentContactToSubmit } from './utils/mapping'
import { useAccount } from './services/useAccount'
import { useFetchPeriodicity } from 'services/useFetchPeriodicity'
import { ScrollBox } from 'components/ScrollBox'

const InformerAssistance = () => {
  return (
    <Informer title="Помощь при составлении заявки">
      <Stack spacing={3}>
        <Text>Если вы затрудняетесь при составлении заявки или у вас нет нужных документов, администратор может помочь.</Text>
        <Box>
          <SupportRequestForm project={projectType.orders}/>
        </Box>
      </Stack>
    </Informer>
  )
}

const formSchema = Yup.object({
  productName: Yup.string().required('Заполните поле'),
  okpd2: Yup.object().test('okpd2', 'Заполните поле', e => !isEmpty(e)),
  description: Yup.string().required('Заполните поле'),
  deliveryQuantityValue: Yup.string().test(
    'deliveryQuantityValue',
    'Введите число больше 0',
    (e) => !Number(isNaN(e) || e < 0)
  ),
  deliveryType: Yup.string().required('Заполните поле'),
  deliveryPeriodicity: Yup.string().required('Заполните поле'),
  keywords: Yup.array().of(Yup.string().required('Заполните поле').default('')),
  activeContragentContactId: Yup.string().required('Заполните поле'),
  deliveryAddress: Yup.object({
    displayString: Yup.string().required('Заполните поле Адрес')
  })
})

export const Edit = () => {
  const { id } = useParams()
  const [isAdmin] = useAccess([rolesType.admin])
  const history = useHistory()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const createParam = queryParams.get('create')
  const create = createParam === 'true'
  const isNew = !id || create
  const { getPeriodicity, dataPeriodicity } = useFetchPeriodicity()
  const { dataAccount, fetchAccount } = useAccount()
  const { createOrder, isLoadingCreate } = useCreateOrder()
  const { updateOrder, isLoadingUpdate } = useUpdateOrder()
  const { data } = useFetchOrder(id)
  const isLoading = isLoadingCreate || isLoadingUpdate

  const isFullyCompletedProfile = useMemo(() => {
    if (isAdmin) return true
    return dataAccount?.accountId ? dataAccount.fullyCompletedProfile : true
  }, [dataAccount, isAdmin])

  const contragentContactsOptions = useMemo(() => {
    return dataAccount?.contragentContacts?.map(i => ({
      label: i?.contactFullName,
      value: i?.id
    }))
  }, [dataAccount?.contragentContacts])

  const handleForm = async (values) => {
    const dataRequest = {
      productName: values?.productName,
      image: values?.image,
      okpd2: values?.okpd2,
      description: values?.description,
      deliveryQuantityValue: values?.deliveryQuantityValue,
      deliveryType: {
        id: values.deliveryType
      },
      creator: {
        id: dataAccount?.accountId
      },
      files: values.files.map(i => i.id),
      activeContragentContact: getActiveContragentContactToSubmit(dataAccount?.contragentContacts, values?.activeContragentContactId),
      deliveryAddress: {
        displayString: values?.deliveryAddress?.displayString,
        longitude: values?.deliveryAddress?.longitude,
        latitude: values?.deliveryAddress?.latitude,
        region: values?.deliveryAddress?.region,
        city: values?.deliveryAddress?.city,
        area: values?.deliveryAddress?.area,
        street: values?.deliveryAddress?.street,
        house: values?.deliveryAddress?.house,
        flat: values?.deliveryAddress?.flat,
        postalCode: values?.deliveryAddress?.postalCode,
        ...values?.deliveryAddress.regionKLADR === '' ? { regionId: values?.deliveryAddress.regionDict?.id } : { regionKLADR: values.deliveryAddress.regionKLADR },
        countryCode: values?.deliveryAddress?.countryCode,
      },
      deliveryPeriodicity: {
        id: values?.deliveryPeriodicity
      }
    }

    if (isNew) {
      await createOrder(dataRequest)
    } else {
      await updateOrder({
        ...dataRequest,
        id
      })
    }
    if (create) {
      history.push('/my-publications/1')
      return
    }
    history.goBack()
  }

  useEffect(() => {
    if (isNew) {
      fetchAccount()
      return
    }
    if (!isEmpty(data)) {
      fetchAccount(data?.creator?.id)
    }
  }, [data])

  useEffect(() => {
    getPeriodicity()
  }, [])


  if (!isAdmin && data?.state?.name === orderStateType.onModeration) {
    return (
      <Container>
        <ScrollBox>
          <Alert status="error" borderRadius="6px" mt="30px">
            <AlertIcon />
            Заявку нельзя редактировать, пока она находится на модерации
          </Alert>
        </ScrollBox>
      </Container>
    )
  }

  return (
    <Container>
      <ScrollBox>
        <PageHeading>
          {isNew ? 'Новая заявка' : 'Редактирование заявки'}
        </PageHeading>
        {!isFullyCompletedProfile && (
          <Alert
            flexDirection="column"
            gap="10px"
            alignItems="flex-start"
            status="error"
            borderRadius="6px"
            mb="30px"
            padding="18px 22px"
          >
            <Flex>
              <AlertIcon />
              <Box>Для дальнейшей работы на платформе требуется заполнить данные о компании</Box>
            </Flex>
            <Button
              ml="30px"
              variant="outline"
              colorScheme="red"
              size="sm"
              onClick={() => history.push('/account/0')}
            >
              Заполнить профиль
            </Button>
          </Alert>
        )}
        <Formik
          enableReinitialize
          validateOnChange={false}
          initialValues={{
            productName: data?.productName ?? '',
            image: data?.image ?? {},
            okpd2: data?.okpD2 ?? {},
            description: data?.description ?? '',
            deliveryQuantityValue: data?.deliveryQuantityValue ?? '',
            deliveryType: data?.deliveryType ? data?.deliveryType?.id : '',
            files: data?.files ?? [],
            activeContragentContactId: data?.activeContragentContact?.id ?? '',
            deliveryAddress: {
              ...data?.deliveryAddress,
              displayString: data?.deliveryAddress?.displayString ?? '',
              longitude: data?.deliveryAddress?.longitude ?? 44.329700,
              latitude: data?.deliveryAddress?.latitude ?? 56.231708,
              region: data?.deliveryAddress?.region ?? '',
              city: data?.deliveryAddress?.city ?? '',
              area: data?.deliveryAddress?.area ?? '',
              street: data?.deliveryAddress?.street ?? '',
              house: data?.deliveryAddress?.house ?? '',
              flat: data?.deliveryAddress?.flat ?? '',
              postalCode: data?.deliveryAddress?.postalCode ?? '',
              regionKLADR: '',
              countryCode: ''
            },
            deliveryPeriodicity: data?.deliveryPeriodicity?.id ?? ''
          }}
          validationSchema={formSchema}
          onSubmit={handleForm}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <ItemColumns pb={0}>
                <BlockCollection>
                  <BlockSection>
                    <Field
                      name="image"
                      label="Изображение"
                      component={ImageUploaderForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="productName"
                      label="Наименование"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="okpd2"
                      label="ОКПД 2"
                      placeholder="Выберите ОКПД 2"
                      component={Okpd2Form}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="description"
                      label="Описание"
                      placeholder="Введите описание"
                      component={TextAreaForm}
                      isDisabled={isLoading}
                    />
                  </BlockSection>
                  <BlockSection>
                    <BlockHeading>Потребность в продукции</BlockHeading>
                    <BlockGrid>
                      <Field
                        name="deliveryQuantityValue"
                        label="Количество"
                        component={InputForm}
                        isDisabled={isLoading}
                      />
                      <Field
                        name="deliveryPeriodicity"
                        label="Периодичность"
                        component={SelectForm}
                        placeholder="Выберите из списка"
                        isDisabled={isLoading}
                        options={dataPeriodicity?.map(item => ({
                          label: item?.name,
                          value: item?.id
                        }))}
                        isClearable
                      />
                    </BlockGrid>
                  </BlockSection>
                  <BlockSection>
                    <BlockHeading>Условия поставки</BlockHeading>
                    <Field
                      name="deliveryType"
                      label="Способ доставки"
                      component={SelectForm}
                      placeholder="Выберите из списка"
                      isDisabled={isLoading}
                      isClearable
                      options={[
                        {
                          label: 'Самовывоз',
                          value: 1
                        },
                        {
                          label: 'Курьером',
                          value: 2
                        },
                        {
                          label: 'Транспортной компанией',
                          value: 3
                        }
                      ]}
                    />
                    <Field
                      isDraggable={false}
                      name="deliveryAddress"
                      component={GeoAddressForm}
                    />
                  </BlockSection>
                  <BlockSection>
                    <BlockHeading>Контакт для связи</BlockHeading>
                    <Field
                      name="activeContragentContactId"
                      label="Сотрудник"
                      isClearable
                      component={SelectForm}
                      isDisabled={isLoading}
                      options={contragentContactsOptions}
                      placeholder="Выберите"
                    />
                  </BlockSection>
                  <BlockSection>
                    <BlockHeading>Документы</BlockHeading>
                    <Text>Загружайте конструктурскую документацию, техническое задание, фото или видео.</Text>
                    <FieldArray
                      name="files"
                      component={FilesForm}
                    />
                  </BlockSection>
                </BlockCollection>
                <Box>
                  <Stack spacing={4} position="sticky" top="20px">
                    <InformerAssistance/>
                    <Informer title="Чем больше информации в заявке, тем проще найти клиента" colorScheme="orange">
                      <Text>При заполнении заявки старайтесь описать товар максимально точно. Поиск в системе учитывает все атрибуты, которые вы вводите. Если у вас есть фотографии, техническая документация и другие вспомогательные файлы, прикрепите их в раздел «Документы».</Text>
                    </Informer>
                  </Stack>
                </Box>
              </ItemColumns>
              <BlockFooter>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isDisabled={(!props.dirty && !create) || !isFullyCompletedProfile}
                  isLoading={isLoading}
                >
                  {isNew ? 'Создать и сохранить в публикациях' : 'Обновить'}
                </Button>
                {!isNew && (
                  <Button
                    colorScheme="gray"
                    onClick={() => history.goBack()}
                  >Отменить</Button>
                )}
              </BlockFooter>
            </Form>
          )}
        </Formik>
      </ScrollBox>
    </Container>
  )
}
